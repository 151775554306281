import { useState } from "react"
import React from "react"
import shivaani from "../../../static/Client/shivani.png"
import Kahteem from "../../../static/Client/Kahteem.png"
import hts from "../../../static/Client/hts.png"
import rr from "../../../static/Client/rr.png"
import amnet from "../../../static/Client/amnet.png"
import adhil from "../../../static/Client/adhil.png"
import actopus from "../../../static/Client/actopus.png"
import tdh from "../../../static/Client/tdh.png"
import sudarshan from "../../../static/Client/sudarshanFans.png"
import refit from "../../../static/Client/refitRepairs.png"
import loadking from "../../../static/Client/loadking.png"
import jagadbandhu from "../../../static/Client/jagadbandhu.png"
import poojaStores from "../../../static/Client/poojaStores.png"
import yogoAhar from "../../../static/Client/yogiAhar.jpg"
// import Kwiku from "../../../static/Client/kiku.jpg"
import pdca from "../../../static/Client/pdca.png"
import adarsh from "../../../static/Client/adarsh.png"
import facenet from "../../../static/Client/FaceApp.png"
import hostel from "../../../static/Client/Hostel.png"
import og from "../../../static/Client/og.jpg"

import html from "../../../static/techStack/html.png"
import css from "../../../static/techStack/css.png"
import js from "../../../static/techStack/js.png"
import react from "../../../static/techStack/react.png"
import express from "../../../static/techStack/express.png"
import node from "../../../static/techStack/nodejs.png"
import vite from "../../../static/techStack/vite.jpg"
import redux from "../../../static/techStack/redux.webp"
import firebase from "../../../static/techStack/firebase.png"
import tailwindcss from "../../../static/techStack/tailwindcss.png"
import php from "../../../static/techStack/php.jpg"
import mongodb from "../../../static/techStack/mongodb.png"
import stripe from "../../../static/techStack/stripe.webp"
import razorpay from "../../../static/techStack/razorpay.png"
import laravel from "../../../static/techStack/laravel.png"
import mysql from "../../../static/techStack/mysql.png"
import jquery from "../../../static/techStack/jquery.png"
import Codeigniter from "../../../static/techStack/Codeigniter.webp"
import android from "../../../static/techStack/android.png"
import flutter from "../../../static/techStack/flutter.png"

import { GoArrowUpRight } from "react-icons/go"
import { FaRegPlayCircle } from "react-icons/fa"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CardPortfolio = ({
  name,
  src,
  alt,
  link,
  description,
  techStack,
  url,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className=" rounded-t-3xl bg-white pb-4">
      <div
        className="overflow-hidden relative rounded-t-3xl"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img className="w-full h-full object-cover" src={src} alt={alt} />
        {isHovered && (
          <div className="absolute space-y-2 top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black/15 bg-opacity-5 hover:bg-opacity-70 text-white transition duration-300 ease-in-out opacity-100">
            <Link to={url} target="_blank">
              <div className="flex items-center gap-2 p-2 rounded-md bg-slate-50">
                <GoArrowUpRight className="text-2xl text-black" />
                <h3 className="text-lg text-black">explore</h3>
              </div>
            </Link>
            <div className="text-start absolute bottom-5 left-5">
              <h4 className="text-lg font-semibold uppercase text-white ">
                Website
              </h4>
              <h4 className="font-bold text-xl text-white">{name}</h4>
            </div>
          </div>
        )}
      </div>
      <div className="space-y-6 divide-y-[1px] ">
        <div className="p-2 md:mt-6 mt-3 space-y-2 md:px-8 px-4">
          <Link
            to={url}
            target="_blank"
            className="flex items-center gap-2 group w-fit"
          >
            <div className="font-bold text-xl text-black">
              <h4 className="relative inline-block">
                {name}
                <span className="absolute h-[0.5px] w-full bg-black bottom-0 left-0 mt-1 transition-transform origin-left transform scale-x-0 group-hover:scale-x-100"></span>
              </h4>
            </div>

            <GoArrowUpRight className="text-xl text-black font-bold  transition ease-in-out delay-150  group-hover:-translate-y-1 group-hover:scale-125 group-hover:duration-300" />
          </Link>
          <p className="text-black leading-2 text-wrap ">{description}</p>
        </div>

        {link && (
          <div className="p-2 md:mt-6 mt-3 space-y-2 md:px-8 px-4 ">
            <Link
              to={link}
              target="_blank"
              className="flex items-center justify-between gap-2 group"
            >
              <h4 className="text-black">Dashboard</h4>
              <Link
                to={link}
                target="_blank"
                className="flex items-center justify-between gap-2 text-black hover:text-blue-400"
              >
                Play Demo
                <FaRegPlayCircle className="md:text-[18px] text-[12px]" />
              </Link>
            </Link>
          </div>
        )}

        <div className="grid gap-4 p-2 md:mt-6 mt-3 md:px-8 px-4">
          <h3 className="text-black">Tech Stack</h3>
          <div className="flex justify-between">
            {techStack?.map((icons, idx) => (
              <img key={idx} src={icons} alt="icons" className="w-6" />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

CardPortfolio.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  techStack: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
}

const Cards = () => {
  const data = {
    features: [
      // {
      //   name: "Oneness Generation",
      //   src: og,
      //   alt: "Oneness Generation",
      //   url: "#",
      //   description:
      //     "Oneness Generation is a youth-led digital platform dedicated to fostering mindfulness, connection, and personal growth. It offers a space for users to share blogs, participate in engaging events, and connect with a like-minded community. With seamless event registrations and personalized user accounts, OG empowers young individuals to embrace a stress-free, joyful, and connected way of life.",
      //   techStack: [js, react, tailwindcss, express, node, mongodb, vite],
      // },
      {
        name: "Sri Pooja Stores",
        src: poojaStores,
        alt: "Pooja Stores",
        url: "https://sripoojastores.com/",
        description:
          "Pooja Stores specializes in shipping pooja kits and religious products globally, ensuring that customers worldwide receive high-quality, authentic items for their spiritual practices. With reliable international delivery, they cater to diverse cultural and devotional needs.",
        techStack: [
          js,
          react,
          redux,
          tailwindcss,
          express,
          node,
          mongodb,
          vite,
          razorpay,
        ],
      },
      {
        name: "Yogi Ahar",
        src: yogoAhar,
        alt: "Yogi Ahar",
        description:
          "The Yogi Ahar Restaurant food application streamlines operations by allowing the manager to oversee activities, kitchen employees to receive and prepare orders, and waiters to efficiently manage table service.",
        techStack: [
          js,
          react,
          redux,
          tailwindcss,
          express,
          node,
          mongodb,
          android,
          vite,
          razorpay,
        ],
      },
      // {
      //   name: "Kwiku",
      //   src: Kwiku,
      //   alt: "Kwiku",
      //   description:
      //     "Kwiku is a dynamic ride-hailing app that connects users with Bike and Auto Services for fast, affordable, and convenient transportation. Offering a seamless booking experience, it is ideal for navigating busy urban areas with ease.",
      //   techStack: [
      //     js,
      //     react,
      //     tailwindcss,
      //     express,
      //     node,
      //     mongodb,
      //     android,
      //     razorpay,
      //   ],
      // },

      {
        name: "PDCA 4M",
        src: pdca,
        alt: "PDCA 4M",
        url: "https://pdcaskill.in/",
        description:
          "PDCA 4M is an innovative web application designed to streamline the training process for firms, training partners, trainers, and trainees. The platform serves as a centralized hub where organizations can effectively manage training programs, schedule sessions, and ensure seamless course enrollment.",
        techStack: [js, react, tailwindcss, express, node, mongodb, vite],
      },

      {
        name: "Adarsh Cricket Academy",
        src: adarsh,
        alt: "Adarsh Academy",
        url: "https://adarshcricketacademy.com/",
        description:
          "Adarsh Cricket Academy is a powerful web application designed to efficiently manage multiple academy branches, streamline student onboarding, and track daily operations. The platform provides a centralized management system for Super Admins, Branch Managers, and Students, ensuring seamless coordination and record-keeping.",
        techStack: [js, react, tailwindcss, firebase, vite],
      },
      {
        name: "Hostel Management system",
        src: hostel,
        alt: "hostel",
        url: "https://hmshostel.netlify.app/",
        description:
          "Our Hostel Management Mobile Application is a modern solution designed to streamline hostel operations, making tenant onboarding, bed allocation, and expense tracking seamless. The platform provides role-based access for Owners, Wardens, and Tenants, ensuring smooth management and transparency.",
        techStack: [flutter, express, node, mongodb],
      },
      {
        name: "FaceNet",
        src: facenet,
        alt: "facenet",
        // url: "https://adarshcricketacademy.com/",
        description:
          "FaceNet Mobile Application is an advanced AI-driven facial recognition system designed to automate and streamline employee attendance tracking. By leveraging cutting-edge facial detection technology, the app enables effortless clock-ins and clock-outs, ensuring a secure, contactless, and efficient workforce management system.",
        techStack: [android, express, node, mongodb, vite, tailwindcss],
      },
      {
        name: "Amnet",
        src: amnet,
        alt: "Amnet Image",
        // link: "https://amnet.com/",
        url: "https://amnet.com/",
        description:
          "Amnet CMS offers comprehensive content management services, providing solutions for businesses seeking efficient digital content management. With a focus on user-friendly interfaces,robust functionalities, Amnet CMS contains content creation, editing, and publishing processes. Its flexible platform accommodates various content types, including text, images, videos, and more, empowering organizations to deliver engaging and dynamic content.",
        techStack: [js, react, laravel, php, mysql, jquery, tailwindcss],
      },
      {
        name: "HTS Biopharma",
        src: hts,
        alt: "HTS Biopharma",
        url: "https://htsbiopharma.com/",
        // link: "https://htsbiopharma.com/",
        description:
          "the pharmaceutical company to overcome industry-specific challenges and achieve significant improvements in compliance management, manufacturing efficiency, quality control, and supply chain optimization. By leveraging the tailored features of HTS Bio Pharma ERP, the organization experienced accelerated growth, enhanced regulatory compliance, and increased operational excellence. The case study exemplifies the transformative impact of HTS Bio Pharma ERP in the biopharmaceutical industry, positioning the company for sustainable success and future growth",
        techStack: [Codeigniter, js, css, html, mysql],
      },
      {
        name: "RR Jewellery",
        src: rr,
        alt: "RR Jewellery",
        link: "https://www.youtube.com/watch?v=2Oi20gON3H8",
        url: "https://myjewels.com.my/",
        description:
          "RR Jewellery's e-commerce site is an extensive collection of exquisite jewelry pieces, ranging from timeless classics to contemporary designs. With a user-friendly interface and secure transactions, customers can effortlessly browse and purchase their desired items online.Offering quality, luxurious shopping experience for every customer.",
        techStack: [html, css, js, laravel, mysql, jquery],
      },
      {
        name: "Tenali Double Horse",
        src: tdh,
        alt: "Tenali Double Horse",
        link: "https://www.youtube.com/watch?v=iUFI6Pq9B-4",
        url: "https://www.tenalidoublehorse.com/",
        description:
          "Tenali Double Horse (TDH) ERP system helped the food processing company streamline operations, drive efficiency, and improve decision-making. The TDH ERP solution provided integrated operations management, real-time visibility, and supply chain optimization, resulting in improved operational efficiency, enhanced inventory management, and better customer satisfaction",
        techStack: [html, css, js, laravel, jquery, mysql],
      },

      {
        name: "Kahteem",
        src: Kahteem,
        alt: "Kahteem",
        link: "https://www.youtube.com/watch?v=GFhvUD5kZx4",
        // url: "https://dev.kahteem.com/",
        description:
          "Discover collection of stylish and functional pieces that transform your space into a haven of comfort and elegance modern designs to timeless classics, our platform offers everything you need to furnish your home with flair. With seamless browsing and secure transactions, desire in a hassle-free shopping experience tailored to your taste and lifestyle. Redefine your living space effortlessly with our diverse range of high-quality furniture, delivered right to your doorstep.",
        techStack: [
          react,
          js,
          redux,
          tailwindcss,
          express,
          node,
          mongodb,
          vite,
          stripe,
        ],
      },
      {
        name: "Shivaani Interiors",
        src: shivaani,
        alt: "Shivaani Interiors",
        link: "https://www.youtube.com/watch?v=WotGH6fRTIw",
        url: "https://shivaaniinteriors.com/",
        description:
          "Shivani Interiors offers a comprehensive range of services, including electrical works, modular kitchen installation, and custom wall paneling solutions. Additionally, they specialize in crafting bespoke wardrobes, providing expert building construction services, and executing flawless plaster of Paris work. From elegant crockery units to stylish living room TV units, Shivani Interiors ensures meticulous attention to detail in every project, complemented by professional repair works and exquisite lighting and painting options.",
        techStack: [html, css, js, react, tailwindcss, vite, firebase],
      },
      {
        name: "Adhil Global Logistics",
        src: adhil,
        alt: "Adhil Global Logistics",
        link: "https://www.youtube.com/watch?v=_Y1ECOZ3R6A",
        url: "https://adilgloballogistics.com/",
        description:
          "Adhil Global Logistics specializes in seamless supply chain solutions, offering efficient transportation and distribution services worldwide. With a focus on reliability and customer satisfaction, they ensure timely delivery and optimized logistics operations for businesses of all sizes.",
        techStack: [html, css, js, react, tailwindcss, vite, firebase],
      },
      {
        name: "Actopus",
        src: actopus,
        alt: "Actopus",
        url: "https://actopusfms.in/",
        description:
          "Actopus provides comprehensive housekeeping and security guard services, ensuring cleanliness and safety for residential and commercial properties. With trained professionals and meticulous attention to detail, Actopus delivers reliable and trusted solutions for maintaining a secure and pure environment.",
        techStack: [html, css, js, react, tailwindcss, vite],
      },
      {
        name: "Sudarshan Fans",
        src: sudarshan,
        alt: "Sudarshan Fans",
        url: "https://sudharshan-fans-comments.netlify.app/",
        description:
          "Sudarshan Fans is a renowned manufacturer of high-quality ceiling fans, known for their energy efficiency, durability, and innovative designs. With a commitment to excellence, Sudarshan Fans offers a wide range of fan models to suit various aesthetic preferences and cooling needs, ensuring superior performance and customer satisfaction.",
        techStack: [
          js,
          react,
          redux,
          tailwindcss,
          express,
          node,
          mongodb,
          vite,
          razorpay,
        ],
      },
      {
        name: "Refit Repairs",
        src: refit,
        alt: "Refit Repairs",
        url: "https://refitrepairs.com/",
        description:
          "Refit Repairs specializes in providing comprehensive repair services for a wide range of electronic devices and appliances. With skilled technicians and a commitment to quality craftsmanship, Refit Repairs ensures efficient and reliable solutions to restore functionality to your gadgets and equipment, extending their lifespan and saving you money. ",
        techStack: [html, css, js, php],
      },
      {
        name: "Load King",
        src: loadking,
        alt: "Load King",
        url: "https://www.cresolinfoserv.com/loadking/",
        description:
          "LoadKing is an innovative platform designed to streamline operations within the trucking industry by connecting lorry offices with drivers seamlessly. The app offers a comprehensive solution that not only facilitates job matching and scheduling but also provides essential support services. Drivers can access a network of mechanics and spare parts suppliers, ensuring they stay on the road with minimal downtime. With LOADKING, logistics management becomes more efficient, reducing operational headaches and improving overall productivity.",
        techStack: [
          react,
          js,
          redux,
          tailwindcss,
          express,
          node,
          mongodb,
          vite,
          stripe,
        ],
      },
      {
        name: "jagadbandhu",
        src: jagadbandhu,
        alt: "jagadbandhu",
        url: "https://jagadbandhu.net/",
        description:
          "Jagadbandhu believes that every individual has the right and capability to realize one’s own potential and purpose as a human being. Enabling individuals to be self-aware about their choices which are in alignment with their passion is one of the core interests of JBA. Acting as a catalyst to enable an individual of all ages to trigger their thinking, emotional and social skills and thus they are empowered to make informed choices in their career and life, thus providing meaningful contribution and steer socio-economic progress to themselves, family, society, nation and the world.",
        techStack: [html, css, js, php],
      },
    ],
  }

  return (
    <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center 2xl:px-[160px] sm:px-[50px] px-[10px]  gap-16 p-4 md:mt-10 mt-4">
      {data.features.map((feature, index) => (
        <CardPortfolio key={index} {...feature} />
      ))}
    </div>
  )
}

export default Cards
